import React from "react";
import { useEffect, useRef, useCallback } from "react";
import { useState } from "react";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import DataControls from "../../components/DataTools/DataControls";
import BudgetVersionsService from "../../services/BudgetVersionsService";
import ComputeKpisService from "../../services/ComputeKpisService";
import KpisService from "../../services/KpisService";
import { getLocalStorage, setLocalStorage } from "../../services/LocalStorage";
import { useLanguageContext } from "../../context/GlobalProvider";
import { showErrorToast } from "../../components/CustomToast";

export default function ComputeKpis(props) {
  const [hotelList, setHotelList] = useState([]);
  const [budgetVersionList, setBudgetVersionList] = useState([]);
  const DefaultHotelId = getLocalStorage("defaultHotelId");
  const [kpisList, setKpisList] = useState([]);
  const [kpiName, setKpiName] = useState(null);
  const [hotelName, setHotelName] = useState(null);
  const [yearsList, setYearsList] = useState(null);
  const [monthList, setMonthList] = useState(null);
  const [visible, setVisible] = useState(false);
  const [progressError, setProgressError] = useState("");
  const [budgetVersionCode, setbudgetVersionCode] = useState(
    getLocalStorage("budgetVersionCode")
  );
  let [progressValue, setProgressValue] = useState(0);
  const [ComputeError, setComputeError] = useState("");

  //new addition
  const [computeStatus, setComputeStatus] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const [errorsHotelName, setErrorsHotelName] = useState("");
  const [errorsKpisName, setErrorsKpisName] = useState("");
  const [errorsYears, setErrorsYears] = useState("");
  const [errorsMonths, setErrorsMonths] = useState("");
  const [errorsBudgetVersionCode, setErrorsBudgetVersionCode] = useState("");

  //p-invalid className
  const [classNameHotelName, setClassNameHotelName] = useState("");
  const [classNameKpisName, setClassNameKpiName] = useState("");
  const [classNameYear, setClassNameYear] = useState("");
  const [classNameMonths, setClassNameMonths] = useState("");
  const [classNameBudgetVersionCode, setClassNameBudgetVersionCode] =
    useState("");

  const toast = useRef(null);
  const interval = useRef(null);
  const month = [
    { id: "1", name: "01" },
    { id: "2", name: "02" },
    { id: "3", name: "03" },
    { id: "4", name: "04" },
    { id: "5", name: "05" },
    { id: "6", name: "06" },
    { id: "7", name: "07" },
    { id: "8", name: "08" },
    { id: "9", name: "09" },
    { id: "10", name: "10" },
    { id: "11", name: "11" },
    { id: "12", name: "12" },
  ];

  const years = [
    { id: "1", name: "2017" },
    { id: "2", name: "2018" },
    { id: "3", name: "2019" },
    { id: "4", name: "2020" },
    { id: "5", name: "2021" },
    { id: "6", name: "2022" },
    { id: "7", name: "2023" },
    { id: "8", name: "2024" },
    { id: "9", name: "2025" },
  ];

  const { t } = useLanguageContext();
  //Restrict double click on a button
  const [clickedButton, setClickedButton] = useState(true);

  //Get Hotel List
  useEffect(() => {
    setHotelList(
      getLocalStorage("userHotels").map(item => ({
        id: item.hotelId,
        name: item.name,
      }))
    );
  }, []);

  //Get Kpis LIST
  useEffect(() => {
    KpisService.lovGetKpisByHotelId(DefaultHotelId, {
      name: "",
      description: "",
      type: "",
    })
      .then(res => {
        setKpisList(
          res.data.map(item => ({
            id: "{" + item.name + "}",
            name: item.name,
          }))
        );
      })
      .catch(error => {
        showErrorToast(toast, t, error);
      });
  }, [DefaultHotelId, t]);

  //Get Budget Version Code
  useEffect(() => {
    BudgetVersionsService.getBudgetVersionsByHotelId(
      DefaultHotelId,
      {
        first: 1,
        rows: 500,
        page: 0,
        sortOrder: null,
        sortField: null,
      },
      {
        budgetVersionCode: "",
        description: "",
      }
    ).then(res => {
      setBudgetVersionList(
        res.data.budgetVersions.map(item => ({
          id: item.budgetVersionId.hotelId,
          name: item.budgetVersionId.budgetVersionCode,
        }))
      );
    });
  }, [DefaultHotelId]);

  //ComputeKpiCollection
  const computeKpi = useCallback(() => {
    setProgressValue(0);
    if (hotelName === null) {
      setErrorsHotelName(t("messageErrorRequiredHotelName"));
      setClassNameHotelName("p-invalid");
    } else {
      setErrorsHotelName("");
      setClassNameHotelName("");
    }

    if (kpiName === null) {
      setErrorsKpisName(t("messageErrorRequiredKpiName"));
      setClassNameKpiName("p-invalid");
    } else {
      setErrorsKpisName("");
      setClassNameKpiName("");
    }

    if (yearsList === null) {
      setErrorsYears(t("messageErrorRequiredYears"));
      setClassNameYear("p-invalid");
    } else {
      setErrorsYears("");
      setClassNameYear("");
    }

    if (monthList === null) {
      setErrorsMonths(t("messageErrorRequiredMonths"));
      setClassNameMonths("p-invalid");
    } else {
      setErrorsMonths("");
      setClassNameMonths("");
    }
    if (budgetVersionCode.length === 0) {
      setErrorsBudgetVersionCode(t("messageErrorRequiredBudgetVersionCode"));
      setClassNameBudgetVersionCode("p-invalid");
    } else {
      setErrorsBudgetVersionCode("");
      setClassNameBudgetVersionCode("");
    }
    if (
      hotelName != null &&
      kpiName != null &&
      yearsList != null &&
      monthList != null &&
      budgetVersionCode.length > 0
    ) {
      setErrorsHotelName("");
      setClassNameHotelName("");
      setErrorsKpisName("");
      setClassNameKpiName("");
      setErrorsYears("");
      setClassNameYear("");
      setErrorsMonths("");
      setClassNameMonths("");
      setErrorsBudgetVersionCode("");
      setClassNameBudgetVersionCode("");

      setClickedButton(true);
      ComputeKpisService.computeKpiCollection(
        getLocalStorage("groupHotel"),
        hotelName,
        kpiName,
        monthList,
        yearsList,
        budgetVersionCode
      )
        .then(res => {})
        .catch(error => {
          setClickedButton(false);
          setVisible(true);
          setComputeError(
            error.response.data.message || error.response.data.error_message
          );
        });

      clearInterval(interval.current);
      interval.current = setInterval(() => {
        ComputeKpisService.computeKpiProgress(getLocalStorage("groupHotel"))
          .then(res => {
            setProgressValue(res.data.computeProgress);
            setComputeStatus(res.data.computeStatus);
            //new added
            setShowMessage(false);
            //res.data.computeProgress >= 100
            if (res.data.computeStatus === false && showMessage) {
              setClickedButton(false);
              toast.current.show({
                severity: "info",
                summary: "Success",
                detail: t("toastMessageSuccessProcessCompleted"),
                life: "200000000",
              });

              clearInterval(interval.current);
            }

            //setProgressValue(res.data.computeProgress);
          })
          .catch(error => {
            setVisible(true);
            clearInterval(interval.current);
            setProgressError(
              error.response.data.message || error.response.data.error_message
            );
          });
      }, 2000);

      return () => {
        if (interval.current) {
          clearInterval(interval.current);
          interval.current = null;
        }
      };
    }
  }, [
    t,
    hotelName,
    kpiName,
    monthList,
    yearsList,
    budgetVersionCode,
    showMessage,
  ]);

  //progressBar
  useEffect(() => {
    clearInterval(interval.current);

    ComputeKpisService.computeKpiProgress(getLocalStorage("groupHotel")).then(
      res => {
        setComputeStatus(res.data.computeStatus);

        if (res.data.computeStatus === true) {
          //new added
          setShowMessage(false);
          clearInterval(interval.current);
          interval.current = setInterval(() => {
            ComputeKpisService.computeKpiProgress(getLocalStorage("groupHotel"))
              .then(res => {
                setComputeStatus(res.data.computeStatus);
                if (res.data.computeStatus === true) {
                  //new added
                  setClickedButton(true);
                  setProgressValue(res.data.computeProgress);

                  //res.data.computeProgress >= 100
                  if (res.data.computeStatus === false) {
                    setShowMessage(false);
                    setClickedButton(false);
                    toast.current.show({
                      severity: "info",
                      summary: "Success",
                      detail: t("toastMessageSuccessProcessCompleted"),
                      life: "200000000",
                    });

                    clearInterval(interval.current);
                  }
                } else {
                  setClickedButton(false);
                  toast.current.show({
                    severity: "info",
                    summary: "Success",
                    detail: t("toastMessageSuccessProcessCompleted"),
                    life: "200000000",
                  });
                  setProgressValue(0);
                  //new added
                }
                setProgressValue(res.data.computeProgress);
              })
              .catch(error => {
                setClickedButton(false);
                setVisible(true);
                clearInterval(interval.current);
                setProgressError(
                  error.response.data.message ||
                    error.response.data.error_message
                );
              });
          }, 2000);
        } else {
          setClickedButton(false);
        }
      }
    );
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    };
  }, [t, showMessage]);

  useEffect(() => {
    if (computeStatus === false) {
      setShowMessage(true);
    }
  }, [computeStatus]);
  function handleHotelNameChange(e) {
    setHotelName(e.target.value);
  }
  function handleKpiNameChange(e) {
    setKpiName(e.value);
  }
  function handleYearsListChange(e) {
    setYearsList(e.value);
  }
  function handleMonthListChange(e) {
    setMonthList(e.value);
  }

  function handleBudgetVersionCodeChange(e) {
    setbudgetVersionCode(e.target.value);
    setLocalStorage("budgetVersionCode", e.target.value);
  }
  useEffect(() => {
    getLocalStorage("budgetVersionCode");
  }, []);
  const dialogFooter = (
    <div>
      <Button
        label="OK"
        className="p-button-danger"
        onClick={() => setVisible(false)}
      />
    </div>
  );
  return (
    <React.Fragment>
      <Toast ref={toast} className="toast" />
      <DataControls displayedImportExportDataControl="false" />
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message={ComputeError || progressError}
        footer={dialogFooter}
        icon="pi pi-info-circle"
        acceptClassName="p-button-danger"
        header="Information"
      />
      <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
        <div className="grid">
          <div className="col-12 md:col-6">
            <div className="p-fluid pr-0 md:pr-6">
              <div className="text-center text-xl font-bold">
                {t("menuItem_10")}
              </div>
              <div className="field mb-3">
                <label htmlFor="hotels" className="font-medium">
                  {t("labelhotelsField")} *
                </label>

                <MultiSelect
                  inputId="hotels"
                  className={classNameHotelName}
                  value={hotelName}
                  options={hotelList}
                  onChange={handleHotelNameChange}
                  optionValue="id"
                  optionLabel="name"
                  placeholder={t("placeholderHotelNameField")}
                  maxSelectedLabels={1}
                  filter
                />
                <span style={{ color: "#e24c4c" }}>{errorsHotelName}</span>
              </div>
              <div className="field mb-3">
                <label htmlFor="kpis" className="font-medium">
                  {t("labelKpisField")} *
                </label>
                <MultiSelect
                  className={classNameKpisName}
                  value={kpiName}
                  options={kpisList}
                  onChange={handleKpiNameChange}
                  optionValue="id"
                  optionLabel="name"
                  placeholder={t("placeholderKpiNameField")}
                  maxSelectedLabels={1}
                  inputId="kpis"
                  filter
                />
                <span style={{ color: "#e24c4c" }}>{errorsKpisName}</span>
              </div>
              <div className="field mb-3">
                <label htmlFor="years" className="font-medium">
                  {t("labelYearsField")} *
                </label>
                <MultiSelect
                  value={yearsList}
                  inputId="years"
                  className={classNameYear}
                  options={years}
                  onChange={handleYearsListChange}
                  optionValue="name"
                  optionLabel="name"
                  placeholder={t("placeholderYearsField")}
                  maxSelectedLabels={1}
                />
                <span style={{ color: "#e24c4c" }}>{errorsYears}</span>
              </div>
              <div className="field mb-3">
                <label htmlFor="Months" className="font-medium">
                  {t("labelMonthsfield")}*
                </label>
                <MultiSelect
                  inputId="Months"
                  className={classNameMonths}
                  value={monthList}
                  options={month}
                  onChange={handleMonthListChange}
                  optionValue="name"
                  optionLabel="name"
                  placeholder={t("placeholderMonthsField")}
                  maxSelectedLabels={1}
                />
                <span style={{ color: "#e24c4c" }}>{errorsMonths}</span>
              </div>
              <div className="field mb-3">
                <label htmlFor="BudgetVersionCode" className="font-medium">
                  {t("labelBudgetversionsCodeField")}*
                </label>
                <Dropdown
                  inputId="BudgetVersionCode"
                  className={classNameBudgetVersionCode}
                  placeholder={t("placeholderBudgetVersionCodeField")}
                  optionValue="name"
                  optionLabel="name"
                  options={budgetVersionList}
                  value={budgetVersionCode}
                  onChange={handleBudgetVersionCodeChange}
                ></Dropdown>
                <span style={{ color: "#e24c4c" }}>
                  {errorsBudgetVersionCode}
                </span>
              </div>
              <Button
                label={t("computeButton")}
                className="w-auto p-button-success"
                loading={clickedButton}
                onClick={e => {
                  computeKpi();
                  setVisible(false);
                }}
              />
              <ProgressBar className="mt-3" value={progressValue} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
